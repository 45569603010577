.icon-container {
  display: flex;
  padding: 0.625em; /* 10px = 0.625em */
  height: 6.25em; /* 100px = 6.25em */
  background-color: white;

  .frame {
      width: 7.5em; /* 120px = 7.5em */
      height: 7.5em; /* 120px = 7.5em */
      overflow: hidden;
      border-radius: 50%; /* Default circular frame */
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.1875em solid orange; /* 3px = 0.1875em */
      box-shadow: 0 0.375em 0.75em rgba(0, 0, 0, 0.3);
      transition: transform 0.3s ease, box-shadow 0.3s ease, border-radius 0.3s ease;

      &:hover {
          transform: scale(1.1);
          box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.4);
      }

      .icon {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
      }
      
      .template {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  }

  // Remove border-radius if template=true
  .template-frame {
      border-radius: 0 !important;
  }
}
